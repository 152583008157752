import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Seo from "../components/seo"
import Content from "../components/Content/Content"
import TitleStyle from "../components/TitleStyle/TitleStyle"
import Layout from "../components/Layout/Layout"
import FlexDiv from "../components/FlexDiv/FlexDiv"
import ContactWrapper from "../components/ContactWrapper/ContactWrapper"
import Image from "../components/ContactWrapper/Image/Image"
import LinkStyle from "../components/LinkStyle/LinkStyle"

const AboutPage = () => {
  const aboutMap = useStaticQuery(graphql`
    query aboutMap {
      allFile(filter: {relativeDirectory: {eq: "about"}}) {
          edges {
              node {
                  publicURL
                  name
                  relativeDirectory
              }
          }
      }
  }
  `)

  return (
    <Layout>
    <Seo title="O nas" />
      <Content>
        <TitleStyle>O nas</TitleStyle>
        <FlexDiv>
          <div>
            <p>Jesteśmy młodą, ale szybko rozwijającą się firmą zajmującą się badaniami, rozwojem, produkcją oraz dystrybucją wysokiej jakości naturalnych kosmetyków oraz naturalnych witamin (suplementów diety), które są projektowane i produkowane zgodnie z najwyższymi standardami jakości.</p>

            <p>Misją naszej firmy jest informowanie i przekazywanie leczniczych właściwości ziół i naturalnych składników poprzez zebrane doświadczenia starożytnej medycyny i podążanie za najnowszymi odkryciami współczesnej nauki.</p>

            <p>Produkty nasze wytwarzane sa tylko i wyłacznie przy użyciu najnowocześniejszych technologii produkcyjnych.</p>

            <p>Używamy trzech głównych rodzajów składników:</p>
            <ul>
              <li>zioła lecznicze z całego świata</li>
              <li>nowoczesne- luksusowe składniki w pełni naturalne (olej jojoba, kolagen, glukozamina, chondroityna, MSM, kolagen, woda termalna, sól z Morza Martwego, kwas hialuronowy, koenzym Q10 itp.)</li>
              <li>dodatkowe witaminy i minerały (antyoksydanty, beta karoten, magnez, prowitamina B5 itp.)</li>
            </ul>

            <p>Podstawą naszej działalności, jest stosowanie najwyższej jakości składników, najnowocześniejszych metod rozwoju i najnowszych technologii produkcyjnych, aby dostarczać naszym klientom produkty tylko najlepszej jakości!</p>

            <p>Oznacza to, że nasze produkty składają się z wyjątkowo wielu składników, w wysokim stężeniu. Nie jesteśmy &quot;kolejną na rynku firmą produkującą kosmetyki naturalne czy zwykłym dostawcą suplementów diety&quot;.</p>

            <p>Obecnie oferujemy około 4 produkty kosmetyczne, które nazywamy &quot;kosmetykami funkcjonalnymi&quot; - kremy do twarzy, kremy na stawy i mięśnie, kremy na żylaki i rozszerzone naczynka, kremy do rak.</p>

            <p>Posiadamy w swojej ofercie również 5 różnych produktów- suplementów diety, które nazywamy &quot;witaminami piękna&quot;.</p>

            <p>Mamy tu na myśli wysokoskoncentrowany peptyd kolagenowy, płynną multiwitaminę, płynną witaminę C, płynne produkty z wyciągiem z grzybów leczniczych( Shiitake oraz Maitake)</p>

            <p>Nasza linia produktów i kategorie produktów są stale rozwijane.</p>

            <p>Słuchamy potrzeb naszych klientów, dlatego wciąż rozwijamy swoją ofertę oraz na co dzień obserwujemy i wdrażamy najnowsze trendy rynkowe.</p>
          </div>
        </FlexDiv>
        <FlexDiv justify='space-evenly'>
          <Image href="https://www.google.com/maps/place/Gy%C3%B3gyvar%C3%A1zs+Kereskedelmi+Kft./@47.957102,21.711458,16z/data=!4m5!3m4!1s0x0:0xf4657aadf8c99453!8m2!3d47.9571135!4d21.7114019?hl=hu" target="_blank" rel="noreferrer"><img src={aboutMap.allFile.edges[0].node.publicURL} alt={aboutMap.allFile.edges[0].node.name} width='100%'/></Image>
          <div>
            <h3>MERCE</h3>
            <ContactWrapper />
            <h3>Obsługa Klienta</h3>
            <LinkStyle href='tel:+48412306780' >Tel. +48 41 230 67 80</LinkStyle>
          </div>
          
        </FlexDiv>
      </Content>
        
    </Layout>
  )
}

export default AboutPage
